<template>
  <div class="c-carousel">
    <FHCarousel
      ref="carousel"
      v-if="element.items"
      class="c-carousel__wrapper u-py u-px"
      :autoHeight="true"
      :options="{perPage: 1, loop: true}"
      @change="onCarouselChange"
    >
      <ComponentLearningElementImageElement
        class="c-carousel__item"
        v-for="(item, i) in element.items"
        :key="item._id"
        :content="item"
        width="1088"
        height="755"
        :tabindex="currentSlide === i ? '0' : '-1'"
      />
    </FHCarousel>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { componentNameNormalize } from '@/utils'
import FHCarousel from '@forlagshuset/v-content-carousel'
import ComponentLearningElementImageElement from '@/components/LearningElements/ImageElement'

export default {
  components: {
    FHCarousel,
    ComponentLearningElementImageElement
  },

  methods: {
    componentNameNormalize(name) {
      return componentNameNormalize(name)
    }
  },

  props: {
    content: Object
  },

  setup(props, ctx) {
    const element = computed(() => props.content)
    const currentSlide = ref(0)

    onMounted(async () => {
    })

    const onCarouselChange = () => {
      currentSlide.value = ctx.refs.carousel.carousel.currentSlide
    }

    return {
      element,
      currentSlide,
      onCarouselChange
    }
  }
}
</script>