<template>
  <div class="c-image-element">
    <VHeading header="h1"
      class="c-image-element__title">
      {{ content.name || content.image_type.name }}
    </VHeading>
    <picture class="c-image-element__picture">
      <img class="c-image-element__img"
        :src="src.url"
        :alt="content.image_type.altText || content.image_type.file.altText"
        :title="content.image_type.license && content.image_type.license.credit ? content.image_type.license.credit : ''" />
    </picture>
    <span class="c-image-element__caption">
      {{ content.image_type.caption }}
    </span>
    <div class="c-image-element__description"
      v-md-html="content.image_type.description" />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import VHeading from '@forlagshuset/v-heading'
import { addCloudinaryUrlParams } from '@/utils'

export default {
  components: {
    VHeading
  },

  props: {
    content: Object
  },

  setup(props) {
    const src = ref('')

    onMounted(() => {
      const coverURL = props.content.image_type && props.content.image_type.file ? props.content.image_type.file.url : ''
      const param = 'w_1000,c_scale'
      src.value = {
        url: addCloudinaryUrlParams(coverURL, param),
      }
    })

    return {
      src
    }
  }
}
</script>

<style lang="scss">
.c-image-element__img {
  max-width: 100%;
}
</style>