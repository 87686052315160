<template>
  <div class="c-article" :class="`c-article-unit--${unitSlug}`">
    <UnitHeader
      v-if="unitSlug"
      :unitSlug="unitSlug"
      type="text"
      :element="article.name"
    />

    <div class="c-article__header u-px">
      <div class="c-article__tags u-py">
        <Tag v-for="tag in article.tags" :key="tag._id" :label="tag.label" />
      </div>
      <VHeading header="h1" class="c-article__title u-px">
        <TextIconBig class="c-article__title-icon" />
        {{ article.name }}
      </VHeading>
    </div>

    <div class="c-article__wrapper u-px">
      <aside class="c-article__col u-px">
        <div v-if="hasLeftSection" class="c-article__left-section">
          <img
            v-if="cover && cover.url"
            ref="leftSectionImage"
            :title="cover.license"
            class="c-article__left-section-image"
            :src="cover.url"
            :data-zoom-src="cover.hd_url"
          />
          <component
            v-for="section in leftMargin.items"
            :key="section.id"
            :is="componentNameNormalize(section.__component)"
            :content="section"
            class="c-article__item"
          />
        </div>
      </aside>

      <main class="c-article__col u-px">
        <component
          v-for="section in articleBlocks"
          :key="section.id"
          :is="componentNameNormalize(section.__component)"
          :content="section"
          class="c-article__item"
        />
      </main>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import mediumZoom from 'medium-zoom'
import { fetchSH } from '@/api'
import { componentNameNormalize, parseLearningElements } from '@/utils'
import { addCloudinaryUrlParams } from '@/utils'
import TextIconBig from '@/assets/svg/Icon_Text_Big'
import UnitHeader from '@/components/UnitHeader'
import VHeading from '@forlagshuset/v-heading'
import Tag from '@/components/Tag'
import ComponentLearningElementTextBox from '@/components/LearningElements/TextBox'
import ComponentLearningElementTextElement from '@/components/LearningElements/TextElement'
import ComponentLearningElementArticleElement from '@/components/LearningElements/ArticleElement'
import ComponentLearningElementSectionTagElement from '@/components/LearningElements/SectionTagElement'
import ComponentLearningElementDbokLinkElement from '@/components/LearningElements/DbokLinkElement'
import ComponentLearningElementLtiElement from '@/components/ComponentLearningElements/LtiElement'
import ComponentLearningElementAudioElement from '@/components/ComponentLearningElements/AudioElement'
import ComponentLearningElementImageElement from '@/components/LearningElements/ImageElement'
import ComponentLearningElementVideoElement from '@/components/ComponentLearningElements/VideoElement'
import Imageset from '@/components/LearningElements/Carousel'

export default {
  components: {
    TextIconBig,
    VHeading,
    UnitHeader,
    Tag,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementArticleElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementDbokLinkElement,
    ComponentLearningElementLtiElement,
    ComponentLearningElementAudioElement,
    ComponentLearningElementImageElement,
    ComponentLearningElementVideoElement,
    Imageset,
  },

  setup(props, ctx) {
    const article = ref({})
    const textSlug = computed(
      () => ctx.root.$options.router.history.current.params.textSlug,
    )
    const unitSlug = computed(
      () => ctx.root.$options.router.history.current.params.unitSlug,
    )
    const leftMargin = ref([])
    const articleBlocks = ref([])
    const cover = ref(null)
    const hasLeftSection = computed(
      () => cover.value || leftMargin.value.length,
    )

    onMounted(async () => {
      article.value = await fetchSH('article-types/' + textSlug.value)
      const content = parseLearningElements(article.value.content)

      if (article.value.cover && article.value.cover.image_type) {
        const coverURL = article.value.cover.image_type.file.url
        const license =
          (article.value.cover.image_type.license &&
            article.value.cover.image_type.license.credit) ||
          ''
        const param = 'w_350,c_scale'
        const paramHD = 'h_1080,c_scale'
        cover.value = {
          url: addCloudinaryUrlParams(coverURL, param),
          hd_url: addCloudinaryUrlParams(coverURL, paramHD),
          license,
        }

        setTimeout(() => mediumZoom(ctx.refs.leftSectionImage, 0))
      }

      leftMargin.value =
        content.filter((item) => item.__component === 'LeftMargin')[0] || []
      articleBlocks.value = content.filter(
        (item) => item.__component !== 'LeftMargin',
      )
    })

    return {
      article,
      articleBlocks,
      componentNameNormalize,
      unitSlug,
      leftMargin,
      hasLeftSection,
      cover,
    }
  },
}
</script>

<style lang="scss">
.c-article {
  &.c-article-unit--echo-1-identity {
    .c-unit__header {
      background-color: $unit-identity-bg;
    }
  }
  &.c-article-unit--echo-2-english-around-the-world {
    .c-unit__header {
      background-color: $unit-english-bg;
    }
  }
  &.c-article-unit--echo-3-citizenship {
    .c-unit__header {
      background-color: $unit-citizenship-bg;
    }
  }
  &.c-article-unit--echo-4-indigenous-peoples {
    .c-unit__header {
      background-color: $unit-indigenous-bg;
    }
  }
  &.c-article-unit--echo-my-grammar {
    .c-unit__header {
      background-color: $unit-grammar-bg;
    }
  }

  &.c-article-unit--echo-welcome-to-echo {
    .c-unit__header {
      justify-content: flex-start;
    }
    .c-breadcrumbs {
      margin: 1rem 0;

      .c-breadcrumbs__crumb:first-of-type {
        display: none;
      }
    }
    .c-unit__cover-placeholder,
    .c-unit__unit-title,
    .c-article__tags,
    .c-article__title-icon,
    .c-article__col:first-child {
      display: none;
    }

    .c-article__col:last-child {
      border: none;
    }

    .c-article__header,
    .c-article__wrapper {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.c-unit__header {
  @include bp-down($tablet) {
    display: none;
  }
}

.c-article__header {
  padding: 0 3rem;
  @include bp(large) {
    width: $large;
  }
  @include bp-down($tablet) {
    padding: 0 1.25rem;
  }
}
.c-header h1 {
  font-size: 1.5rem;
}

.c-article__tags {
  width: 25%;
  display: inline-block;
  text-align: right;
  padding-left: 4rem;
  @include bp-down($tablet) {
    display: none;
  }
}

.c-article__title {
  width: calc(75% - 6rem);
  display: inline-block;
  vertical-align: top;
  @include bp-down($tablet) {
    padding: 0;
    width: 100%;
  }
}

.c-article__wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;

  @include bp-down($tablet) {
    flex-wrap: wrap-reverse;
    padding: 0 0.25rem;
  }

  .c-header h2,
  .c-text-element__title h3 {
    font-size: 1.5rem;
    font-weight: 300;
  }

  .c-audio-element {
    padding: 1rem 0;
    @include bp-down($tablet) {
      padding: 0;
    }
  }

  .c-image-element {
    .c-image-element__title,
    .c-image-element__description {
      display: none;
    }
  }

  .c-video {
    @include bp-down($tablet) {
      padding-bottom: 0;
    }
  }

  .c-video__wrapper {
    width: initial;
  }

  .c-button {
    border: 0.0625rem solid $color-divider;
  }
}
.c-article__title-icon {
  vertical-align: middle;
}

.c-article__col {
  display: flex;
  flex-direction: column;
  @include bp-down($tablet) {
    margin: 0 20px;
    padding: 0;
    max-width: calc(100%-40px);
  }
  &:first-child {
    max-width: 350px;
    width: 100%;

    .c-article__item {
      h2,
      h3 {
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
  &:last-child {
    max-width: $article-width;
    border-left: 1px solid $color-divider;
    @include bp-down($tablet) {
      border-left: none;
      max-width: 100%;
    }
  }

  p {
    @include bp-down($tablet) {
      font-size: 1em;
    }
  }
}

.c-article__left-section-image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
  @include bp-down($tablet) {
    display: none;
  }
}
.medium-zoom-overlay {
  z-index: 999;
}
.medium-zoom-image {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;

  &--opened {
    cursor: zoom-out;
    z-index: 9999;
  }
}
</style>